import React from "react"

import {Nav} from "react-bootstrap"
import {Link} from "gatsby"

import {FaChevronRight} from 'react-icons/fa';

import {Col, Row} from "react-bootstrap"

import { StaticQuery, graphql } from "gatsby"

const types = [
  {name: 'Color', title: 'Цветной'},
  {name: 'Black', title: 'Черный'}
]

const Menu = ({location, lg, data, filter = (item) => true}) => {
  return (<Row>
    {data.site.siteMetadata.contacts.filter(filter).map((contact, index) => {
    return (
        <Col lg={lg} md={12} className="price-menu" key={index}>
          <h4 className="mt-3 mb-1">{contact.city}</h4>
          <span className="mr-2">{contact.address}</span>
          <Nav className="flex-column mt-3">
            {types.map((type, index) => {
              const pathName = `/${contact.slug}/${type.name.toLowerCase()}/`
              return (
                <Link
                  key={index}
                  to={pathName}
                  className={`py-2 color-white mb-2 type-${type.name.toLowerCase()} ${location.pathname === pathName ? 'active': ''}`}
                >
                  <FaChevronRight style={{marginRight: "0.5rem", fontSize: "0.8rem"}} />
                  Прайс на {type.title.toLowerCase()} металл
                </Link>
              )
            })}
          </Nav>
        </Col>
    )
  })}
  </Row>)
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contacts {
              slug
              city
              address
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} {...props} />}
  />
)